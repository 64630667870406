const routes = [
  {
    path: "/",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      { path: "", component: () => import("src/pages/Index.vue") },
      { path: "products", component: () => import("src/pages/Index.vue") },
      { path: "datastat", component: () => import("src/pages/DataStat.vue") },
      {
        path: "signin",
        component: () => import("src/pages/SigninUser.vue"),
      },
      {
        path: "signup",
        component: () => import("src/pages/SignupUser.vue"),
      },
      {
        path: "customerservice",
        component: () => import("src/pages/CustomerService.vue"),
      },
      {
        path: "/product/:productkey",
        component: () => import("src/pages/product.vue"),
      },
      {
        path: "/product/api/:productkey",
        component: () => import("src/pages/productapi.vue"),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/ErrorNotFound.vue"),
  },
];

export default routes;
